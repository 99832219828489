
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    DetailsTable,
    TmStatus,
    DetailsHero,
    TmButton,
    InfoCard,
    PageContent,
    WhiteBlock,
  },
  setup() {
    const { openModal } = useModals()
    const disableSSO = () => {
      openModal('confirmation', {
        title: 'Disable Singlr Sign-On',
        text: 'You are going to turn off the Single Sign-On feature. Workspace users will not be able to log in using SSO anymore. Are you sure you would like to disable this feature?',
        btnColor: 'error',
        btnText: 'Disable SSO',
      })
    }
    const breadcrumbs = [
      { label: 'Workspace settings', name: 'base.admin' },
      { label: 'Single Sign-On (SSO) settings' },
    ]
    const samlDetails = [
      {
        label: 'Identity provider Entity ID',
        value: 'https://pingone.com/idp/cd-270493788.textmagic',
      },
      {
        label: 'Identity provider SSO URL',
        value: 'https://sso.connect.pingidentity.com/sso/idp/SSO.saml2?idpid',
      },
      {
        label: 'Identity provider SLO URL',
        value: 'https://sso.connect.pingidentity.com/sso/idp/SSO.saml2?idpid',
      },
      {
        label: 'Public x509 certificate',
        slot: 'certificate',
        value: 'Standard Strength Certificate (2048-bit)',
      },
      {
        label: 'Force SSO only',
        slot: 'force-sso',
        value: 'SSO AND TEXTMAGIC PASSWORD',
      },
    ]
    const tutorials = [
      require('@/assets/images/suite.png'),
      require('@/assets/images/clear-logo.svg'),
      require('@/assets/images/centrify-logo.svg'),
      require('@/assets/images/okta.svg'),
      require('@/assets/images/lastpass-logo.svg'),
      require('@/assets/images/azure-logo.svg'),
      require('@/assets/images/auth0-logo.svg'),
      require('@/assets/images/onelogin-logo.svg'),
    ]

    return {
      tutorials,
      breadcrumbs,
      disableSSO,
      openModal,
      samlDetails,
    }
  },
})
